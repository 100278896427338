import React, { useEffect, useMemo, useRef, useState } from 'react';

import { 
  ArrowLeftIcon,
  ClockIcon, 
} from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router';
import { useLoaderData } from 'react-router-dom';

import { getClinicalDetail } from '../api.js';
import DisplayFormattedText from '../AskPage/components/markdown.js';
import ReferenceView from '../Common/ChatPanal/ReferenceView.js';
import { useLanguage } from '../utils/context/lang.js';
import { useLayout } from '../utils/context/layout.js';

import { i18n } from './i18n/clinical.js';

export function ClinicalDetail() {
  const { clinicalDetailData } = useLoaderData();
  const navigate = useNavigate();
  const [ selectedReference, setSelectedReference ] = useState(null);
  const [ isReferenceVisible, setIsReferenceVisible ] = useState(false);
  const containerRef = useRef(null);
  const { collapseSskyebar } = useLayout();
  const { lang } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Handle responsive sskyebar collapse
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWskyth = containerRef.current.offsetWskyth;
        if (containerWskyth < 400) {
          collapseSskyebar();
        }
      }
    };

    // Initial check
    handleResize();

    // Add resize listener
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const extractLinkDomain = (url) => {
    const urlObj = new URL(url);
    return urlObj.hostname;
  };

  const handleReferenceClick = (reference, index) => {
    setIsReferenceVisible(true);
    setSelectedReference({
      sources: clinicalDetailData.sources?.map(ref => ({
        title: ref.title,
        url: ref.link,
        site_name: extractLinkDomain(ref.link),
        description: ref.description
      })),
      referenceIndex: index
    });
  };

  const handleViewAllReferences = (index) => {
    setIsReferenceVisible(true);
    setSelectedReference({
      sources: clinicalDetailData.sources?.map(ref => ({
        title: ref.title,
        url: ref.link,
        site_name: extractLinkDomain(ref.link),
        description: ref.description
      })),
      referenceIndex: index
    });
  };

  return (
    <div ref={containerRef} className="relative min-h-screen bg-base-100 flex">
      <div className={`flex-1 pt-6 transition-all duration-300 ${isReferenceVisible ? 'pr-[400px]' : ''}`}>
        {/* Main Content */}
        <div className="max-w-4xl mx-auto px-4 md:px-8 pb-24">
          {clinicalDetailData && (
            <>
              {/* Basic Information */}
              <div className="mb-6">
                <h2 className="text-xl font-bold mb-4">{clinicalDetailData.official_title}</h2>
                <div className="grid grid-cols-3 gap-4 bg-base-200 p-4 rounded-lg">
                  {
                    clinicalDetailData.nct_id && (
                      <div className="space-y-0.5">
                        <p className="text-xs text-base-content/60">{i18n.NCT_ID[lang]}</p>
                        <a
                          href={`https://clinicaltrials.gov/study/${clinicalDetailData.nct_id}`}
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="block whitespace-pre-wrap hover:underline"
                        >
                          <p className="text-sm font-medium">{clinicalDetailData.nct_id}</p>
                        </a>
                      </div>
                    )
                  }
                  {
                    clinicalDetailData.current_status && (
                      <div className="space-y-0.5">
                        <p className="text-xs text-base-content/60">{i18n.CURRENT_STATUS[lang]}</p>
                        <p className="text-sm font-medium">{clinicalDetailData.current_status}</p>
                      </div>
                    )
                  }
                  {
                    clinicalDetailData.phase && (
                      <div className="space-y-0.5">
                        <p className="text-xs text-base-content/60">{i18n.PHASE[lang]}</p>
                        <p className="text-sm font-medium">{clinicalDetailData.phase}</p>
                      </div>
                    )
                  }
                  {
                    clinicalDetailData.drug_name && (
                      <div className="space-y-0.5">
                        <p className="text-xs text-base-content/60">{i18n.DRUG_NAME[lang]}</p>
                        <p className="text-sm font-medium">{clinicalDetailData.drug_name}</p>
                      </div>
                    )
                  }
                  {
                    clinicalDetailData.indication_name && (
                      <div className="space-y-0.5">
                        <p className="text-xs text-base-content/60">{i18n.INDICATION[lang]}</p>
                        <p className="text-sm font-medium">{clinicalDetailData.indication_name}</p>
                      </div>
                    )
                  }
                  {
                    clinicalDetailData.actual_enrollment && (
                      <div className="space-y-0.5">
                        <p className="text-xs text-base-content/60">{i18n.ENROLLMENT[lang]}</p>
                        <p className="text-sm font-medium">{clinicalDetailData.actual_enrollment}</p>
                      </div>
                    )
                  }
                </div>
              </div>

              {/* Study Design */}
              {
                clinicalDetailData.design && (
                  <div className="mb-8">
                    <h3 className="text-xl font-semibold mb-4">{i18n.STUDY_DESIGN[lang]}</h3>
                    <div className="prose max-w-none">
                      <DisplayFormattedText
                        text={clinicalDetailData.design}
                        onReferenceClick={handleReferenceClick}
                      />
                    </div>
                  </div>
                )
              }

              {/* Safety Data */}
              {
                clinicalDetailData.safety && (
                  <div className="mb-8">
                    <h3 className="text-xl font-semibold mb-4">{i18n.SAFETY_DATA[lang]}</h3>
                    <div className="prose max-w-none">
                      <DisplayFormattedText
                        text={clinicalDetailData.safety}
                        onReferenceClick={handleReferenceClick}
                      />
                    </div>
                  </div>
                )
              }

              {/* Efficacy Data */}
              {
                clinicalDetailData.efficacy && (
                  <div className="mb-8">
                    <h3 className="text-xl font-semibold mb-4">{i18n.EFFICACY_DATA[lang]}</h3>
                    <div className="prose max-w-none">
                      <DisplayFormattedText
                        text={clinicalDetailData.efficacy}
                        onReferenceClick={handleReferenceClick}
                      />
                    </div>
                  </div>
                )
              }

              {/* Key Findings */}
              {
                clinicalDetailData.key_findings?.length && (
                  <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-4">{i18n.KEY_FINDINGS[lang]}</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                      {clinicalDetailData.key_findings?.map((finding, index) => (
                        <div 
                          key={index} 
                          className="bg-base-200 p-3 rounded"
                        >
                          <p className="text-sm mb-1">{finding.finding}</p>
                          <p className="text-xs text-gray-500 flex items-center">
                            <ClockIcon className="w-3 h-3 mr-1" />
                            {finding.timestamp}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              }

              {/* Companies */}
              {(clinicalDetailData.lead_company || clinicalDetailData.partner_companies?.length) && (
                <div className="mb-4">
                  <h3 className="text-xl font-semibold mb-4">{i18n.RELATED_COMPANIES[lang]}</h3>
                  <div className="space-y-2">
                    {
                      clinicalDetailData.lead_company && (
                        <div>
                          <p className="text-sm text-gray-500">{i18n.LEAD_COMPANY[lang]}</p>
                          <p className="font-medium">{clinicalDetailData.lead_company}</p>
                        </div>
                      )
                    }
                    {
                      clinicalDetailData.partner_companies?.length && (
                        <div>
                          <p className="text-sm text-gray-500">{i18n.PARTNER_COMPANIES[lang]}</p>
                          {clinicalDetailData.partner_companies?.map((company, index) => (
                            <p key={index} className="font-medium">{company}</p>
                          ))}
                        </div>
                      )
                    }
                  </div>
                </div>
              )}

              {/* Sources */}
              {clinicalDetailData.sources?.length > 0 && (
                <div className="mb-4">
                  <h3 className="text-xl font-semibold mb-4">{i18n.SOURCES[lang]}</h3>
                  <div className="flex flex-col space-y-2">
                    {clinicalDetailData.sources.map((reference, index) => (
                      <a
                        key={index}
                        href={reference.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-fit cursor-pointer hover:underline"
                      >
                        <p className="text-sm text-primary-color">[{index + 1}] {reference.title}</p>
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Reference Section */}
      {isReferenceVisible && selectedReference && (
        <div className="fixed top-0 right-0 z-40 h-screen bg-base-100 w-[400px]">
          <ReferenceView 
            reference={selectedReference}
            isChatVisible={true}
            onClose={() => {
              setIsReferenceVisible(false);
            }}
          />
        </div>
      )}
    </div>
  );
}

// Combined loader that handles both routes
export async function clinicalDetailLoader({ params }) {
  const { id } = params;
  
  // fetch data
  const result = await getClinicalDetail(id);

  // Return data
  return { 
    id,
    clinicalDetailData: result?.[0] || null,
  };
}
